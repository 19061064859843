<template>
  <div>
    <div v-if="business">
      <v-container fluid>
        <v-row class="pa-0 ma-0">
          <h1>
            Horario de
            <span class="font-weight-light">
              {{ business.shortName }}
            </span>
          </h1>
        </v-row>
      </v-container>

      <v-snackbar top :timeout="3000" v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>

      <v-container fluid class="screen-width fill-height">
        <schedule-c :loading="loading" :schedule="businessCopy.schedule" />

        <!-- <v-row class="pa-0 ma-0">
          <v-col class="mt-5" cols="12">
            <h2>Días en que se realizan las entregas</h2>
            <v-divider class="mb-5"></v-divider>
          </v-col>
          <v-col
            v-for="(item, index) of deliveryDays"
            :key="index"
            class="mt-5"
            cols="3"
          >
            <v-switch
              v-model="businessCopy[item.value]"
              :label="item.day"
              :loading="loading"
            ></v-switch>
          </v-col>
        </v-row> -->
        <v-row class="pa-0 ma-0">
          <v-col cols="12">
            <v-divider></v-divider>
            <v-row justify="end">
              <v-btn
                class="save-btn"
                @click="updateSchedule"
                :loading="loading"
                color="primary"
                width="180px"
                min-height="45px"
                dark
              >
                Guardar
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";

import scheduleC from "@/components/own-schedule";

export default {
  name: "owner-schedule",
  components: {
    scheduleC,
  },
  data() {
    return {
      business: null,
      snackbar: false,
      snackbarText: null,
      loading: false,
      businessCopy: {},
      deliveryDays: [
        { day: "Lunes", value: "activeDeliveryMonday" },
        { day: "Martes", value: "activeDeliveryTuesday" },
        { day: "Miércoles", value: "activeDeliveryWednesday" },
        { day: "Jueves", value: "activeDeliveryThursday" },
        { day: "Viernes", value: "activeDeliveryFriday" },
        { day: "Sábado", value: "activeDeliverySaturday" },
        { day: "Domingo", value: "activeDeliverySunday" },
      ],
    };
  },
  watch: {
    selectedBusiness(e) {
      if (e[".key"] != this.business[".key"]) {
        this.business = null;
        this.getBusinessData();
      }
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
  },
  mounted() {
    this.$store.state.visibleSearch = false;
    this.getBusinessData();
  },
  methods: {
    updateSchedule() {
      let days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];

      this.loading = true;

      let data = {
        activeDeliveryMonday: this.businessCopy.activeDeliveryMonday || false,
        activeDeliveryTuesday: this.businessCopy.activeDeliveryTuesday || false,
        activeDeliveryWednesday:
          this.businessCopy.activeDeliveryWednesday || false,
        activeDeliveryThursday:
          this.businessCopy.activeDeliveryThursday || false,
        activeDeliveryFriday: this.businessCopy.activeDeliveryFriday || false,
        activeDeliverySaturday:
          this.businessCopy.activeDeliverySaturday || false,
        activeDeliverySunday: this.businessCopy.activeDeliverySunday || false,
      };

      days.forEach((day) => {
        this.businessCopy.schedule[day] = this.businessCopy.schedule[
          day
        ].filter((item) => item.opensAt && item.closesAt);
      });

      data.schedule = this.businessCopy.schedule;

      db.collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .update(data)
        .then(() => {
          this.loading = false;
          this.snackbar = true;
          this.snackbarText = "Información actualizada correctamente.";
        })
        .catch(() => {
          this.loading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },

    async getBusinessData() {
      if (!this.selectedBusiness) {
        this.$router.push({ path: "/" });
      }

      let business = await db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .get();

      business = business.data();
      business[".key"] = this.selectedBusiness[".key"];

      this.business = JSON.parse(JSON.stringify(business));
      this.businessCopy = JSON.parse(JSON.stringify(business));

      this.daySelected = "monday";
    },
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
  filters: {
    translateDay(day) {
      switch (day) {
        case "monday":
          return "Lunes";
        case "tuesday":
          return "Martes";
        case "wednesday":
          return "Miércoles";
        case "thursday":
          return "Jueves";
        case "friday":
          return "Viernes";
        case "saturday":
          return "Sábado";
        case "sunday":
          return "Domingo";
        default:
          return "dia";
      }
    },
  },
};
</script>

<style scoped>
.schedule {
  width: 100%;
  margin-top: 10px;
}
.days {
  background-color: white !important;
  width: 100%;
  min-height: 300px;
  margin-top: 10px;
}
.theme--light.v-list {
  background: white !important;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f06f3d;
}
.save-btn {
  margin-top: 20px;
}
</style>
